<div (click)="customizer === '' ? Customizer('option') : Customizer('')">
  <app-feather-icons [icon]="'settings'"></app-feather-icons>
</div>
<div class="customizer-contain" [class.open]="customizer != ''">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-header">
      <svg
        (click)="Customizer('')"
        class="icon-close"
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.2463 29.0142C21.9534 29.0142 21.6604 28.9063 21.4292 28.6751C21.2142 28.4575 21.0936 28.1639 21.0936 27.858C21.0936 27.5521 21.2142 27.2585 21.4292 27.0409L29.97 18.5001L21.4292 9.95923C21.2142 9.74164 21.0936 9.44805 21.0936 9.14214C21.0936 8.83623 21.2142 8.54265 21.4292 8.32506C21.8763 7.87798 22.6163 7.87798 23.0634 8.32506L32.4213 17.683C32.8684 18.1301 32.8684 18.8701 32.4213 19.3171L23.0634 28.6751C22.8321 28.9063 22.5392 29.0142 22.2463 29.0142Z"
          fill="#E05F57"
        />
        <path
          d="M31.3421 19.6562H5.39581C4.76373 19.6562 4.23956 19.1321 4.23956 18.5C4.23956 17.8679 4.76373 17.3438 5.39581 17.3438H31.3421C31.9741 17.3438 32.4983 17.8679 32.4983 18.5C32.4983 19.1321 31.9741 19.6562 31.3421 19.6562Z"
          fill="#E05F57"
        />
      </svg>
      <h5>{{ 'Settings' | translate }}</h5>
      <ng-template #popup let-modal>
        <div class="modal-header modal-copy-header">
          <h5 class="headerTitle mb-0">
            {{ 'Customizer configuration' | translate }}
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          ></button>
        </div>
        <div class="modal-body">
          <div class="modal-header config-popup">
            <p>
              To replace our design with your desired theme. Please do
              configuration as mention
            </p>
            <p>
              <b> Path : src > app > shared > services > layout.service.ts </b>
            </p>
            <div>
              <pre>
                <code>
                  public config = &#123;
                    settings&#58; &#123;
                        layout&#58; '{{layout.config.settings.layout}}',
                        layout_type&#58; '{{layout.config.settings.layout_type}}',
                        layout_version&#58; '{{layout.config.settings.layout_version}}',
                        sidebar_type&#58; '{{layout.config.settings.sidebar_type}}',
                    &#125;,
                    color&#58; &#123;
                        primary_color&#58; '{{layout.config.color.primary_color}}',
                        secondary_color&#58; '{{layout.config.color.secondary_color}}'
                    &#125;
                  &#125;
                </code>
              </pre>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div
        class="tab-pane fade"
        [class.active]="customizer == 'option'"
        [class.show]="customizer == 'option'"
        id="c-pills-home"
        role="tabpanel"
        aria-labelledby="c-pills-home-tab"
      >
        <div class="option-group">
          <div class="row">
            <div class="col-6">
              <h6>{{ 'Language' | translate }}</h6>
              <p>{{ 'Select the language' | translate }}</p>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
              <app-form
                class="w-100"
                [questions]="languageQuestions"
                [key]="'languageTab'"
                (responses)="onLanguageResponsesChanges($event)"
              >
              </app-form>
            </div>
          </div>
        </div>
        <div class="option-group theme">
          <h6>{{ 'Interface Theme' | translate }}</h6>
          <p>{{ 'Customise your application theme' | translate }}</p>
          <div class="d-flex justify-content-between mt-5">
            <div (click)="customizeMixLayout('default')">
              <img
                src="/assets/images/layouts/light-layout.jpg"
                class="img-fluid"
                [class.active]="MIXLayout == 'default'"
                alt="default layout"
              />
              <h6 class="text-center mt-2">{{ 'Light' | translate }}</h6>
            </div>
            <div (click)="customizeMixLayout('dark-only')">
              <img
                src="/assets/images/layouts/dark-layout.jpg"
                class="img-fluid"
                [class.active]="MIXLayout == 'dark-only'"
                alt="dark layout"
              />
              <h6 class="text-center mt-2">{{ 'Dark' | translate }}</h6>
            </div>
          </div>
        </div>
        <div class="option-group">
          <h6>{{ 'Accent Color' | translate }}</h6>
          <p>{{ 'Select your application color' | translate }}</p>
          <ul class="d-flex flex-wrap color-picker mt-4">
            @for (color of colors; track $index) {
              <li
                class="color-picker"
                [class.active]="primary_color === color.color"
                (click)="customizeLayoutColor(color.color)"
              >
                <div [ngStyle]="{ 'background-color': color.color }"></div>
              </li>
            }
          </ul>
        </div>
        <div class="option-group font">
          <div class="row">
            <div class="col-6">
              <h6>{{ 'Font' | translate }}</h6>
              <p>{{ 'Select your font' | translate }}</p>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
              <app-form
                class="w-100"
                [questions]="fontQuestions"
                [key]="'fontTab'"
                (responses)="onFontResponsesChanges($event)"
              >
              </app-form>
            </div>
          </div>
        </div>
        @if (isAllowedToChangeBanner) {
          <div class="option-group">
            <div class="row">
              <div class="col-8">
                <h6>{{ 'First Banner' | translate }}</h6>
                <p>{{ 'Send PNG or JPG image to banner' | translate }}</p>
              </div>
              <div class="banner m-t-20">
                <app-upload-photo
                  #childFirstComponentRef
                  [key]="'thumbnail'"
                  [isMultiple]="false"
                  [dropzoneDivClass]="'col-12'"
                  (uploadedImagesChange)="onUploadedFirstBannerChange($event)"
                ></app-upload-photo>
              </div>
              <div class="main-button-width d-flex justify-content-end">
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="handleRemoveFirstBanner()"
                >
                  {{ 'Remove current' | translate }}
                </button>
                <button
                  class="btn btn-blue"
                  type="button"
                  (click)="handleSendFirstBanner()"
                >
                  {{ 'Save' | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="option-group">
            <div class="row">
              <div class="col-8">
                <h6>{{ 'Second Banner' | translate }}</h6>
                <p>{{ 'Send PNG or JPG image to banner' | translate }}</p>
              </div>
              <div class="banner m-t-20">
                <app-upload-photo
                  #childSecondComponentRef
                  [key]="'thumbnail'"
                  [isMultiple]="false"
                  [dropzoneDivClass]="'col-12'"
                  (uploadedImagesChange)="onUploadedSecondBannerChange($event)"
                ></app-upload-photo>
              </div>

              <div class="main-button-width d-flex justify-content-end">
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="handleRemoveSecondBanner()"
                >
                  {{ 'Remove current' | translate }}
                </button>
                <button
                  class="btn btn-blue"
                  type="button"
                  (click)="handleSendSecondBanner()"
                >
                  {{ 'Save' | translate }}
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
